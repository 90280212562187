
.marginB100 {
  margin-bottom: 100px;
}

.buttonEntry {
  background-color: #e6e6e8;
  border: none;
  width: 260px;
  height: 56px;
  color: #2e3036;
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.highlighted-text {
  background-image: url(../../assets/images/user-pages/Group.png);
  /* background-position: 50% 50%; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(-98.03deg);
}

.boderBody {
  border: 1px solid #f6f6f8;
  box-shadow: 0px 1px 2px rgba(22, 28, 36, 0.08);
  border-radius: 24px;
  max-width: 455px;
  padding: 24px;
}

.titleLogin {
  font-size: 16px;
  line-height: 33px;
  font-weight: bold;
}

.boderBody input[type="text"],
.boderBody input[type="password"],
.boderBody input[type="email"] {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border: 1px solid #7B7C81;
  border-radius: 8px;
  font-size: 16px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  transition: width 0.4s ease-in-out;
}

.boderBody input[type="text"]:focus {
  width: 100%;
}

.buttonLogin {
    background-color: #3866F7;
    border: none;
    width: 100%;
    margin-top:50px;
    height: 56px;
    color: #2e3036;
    border-radius: 8px;
    padding: 16px 24px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    color: #fff;
  }

  label {
    color: #7B7C81;
    font-size: 16px;
  }

  .test {
    position: absolute;
    background-image: url(../../assets/images/user-pages/eye.png);
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    border: 0;
    left: 15px;
    bottom: 15px;
    background-position: right 0px center;
  }