.f-14 {
    font-size: 14px;
}

.f-12 {
    font-size: 12px;
}

.step-content {
    margin-bottom: 80px;
}

.mt-info {
    margin-top: 90px;
}

.navbar-reserve {
    display: flex;
    width: 100%;
    height: 74px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(22, 28, 36, 0.05);
    margin-top: 10px;
}

.navbar-reserve .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reserve-header-handle {
    display: flex;
    height: 60px;
}

.reserve-header-handle .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.home-handle {
    font-weight: 700;
    color: #4E4D59;
    cursor: pointer;
    display: flex;
}

.pr-header-handle {
    font-weight: 700;
    color: rgba(169, 169, 184, 0.8);
    cursor: pointer;
}

.container-form {
    padding: 16px 20px;
    background: #FFFFFF;
    border-radius: 24px;
}

.item-body {
    padding: 6px 8px;
    border: 1px solid #F5F5F7;
    border-radius: 8px;
    margin: 10px 0;
}

.item-body .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body-title-form {
    display: flex;
    align-items: center;
}

.price-body {
    font-weight: 700;
    color: #4E4D59;
}

.checkHeight {
    height: 18px;
    width: 18px;
    margin-left: 5px;
}

.info-reserve-step1 {
    background: #FFFFFF;
    padding: 16px 20px;
    margin-top: 30px;
}

.info-reserve-step1 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.step1-1 {
    font-size: 12px;
    color: #8A8A99;
    font-weight: 400;
}

.step1-2 {
    font-size: 12px;
    color: #4E4D59;
    font-weight: 400;
}

.btn-footer {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid #F5F5F7;

}

.btn-continue-step {
    padding: 8px 12px;
    width: 320px;
    height: 40px;
    border: 0;
    color: #fff;
    font-weight: 700;
    background: #1998FF;
    border-radius: 8px;
    margin: 20px 0;
}


.icon-titr-purple {
    width: 8px;
    height: 24px;
    background: #7563DC;
    border-radius: 50px;
    margin-left: 8px;
}

/* STEP2 */

.notice-step2 {
    height: 130px;
    background: rgba(25, 152, 255, 0.16);
    border-radius: 24px;
}

.notice-step2 .container {
    padding: 16px 12px;
}

.title-notice-step2 {
    font-weight: 700;
    font-size: 14px;
    color: #1998FF;
}

.des-notice-step2 {
    color: #4E4D59;
    font-weight: 400;
}

.tooltip-btn {
    font-weight: 700;
    font-size: 12px;
    color: #1998FF;
    border: 0;
    background-color: transparent;
    line-height: 24px;
}

.month-step2 {
    color: #4E4D59;
    font-weight: 700;
    text-align: center;

}

.day-step2 {
    text-align: center;
    color: #8A8A99;
    font-size: 12px;
}

.range-step2 {
    text-align: center;
    font-size: 12px;
    color: #7563DC;
}

.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* Enable touch scrolling for iOS devices */
    scroll-snap-type: x mandatory;
    /* Optional: Enables snapping behavior */
    -ms-overflow-style: none;
    /* Hides scrollbar for Internet Explorer and Edge */
    scrollbar-width: none;
    /* Hides scrollbar for Firefox */
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none;
    /* Hides scrollbar for Chrome, Safari, and Opera */
}

.item {
    flex: 0 0 auto;
    background: #FFFFFF;
    margin-right: 5px;
    scroll-snap-align: start;
    width: 72px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0px 2px 8px rgba(22, 28, 36, 0.05);
    border-radius: 8px;
    border: 1px solid #161c240d;
    cursor: pointer;
}

.item.selected {
    background-color: #7563DC;
    /* Change this to your desired background color */
    color: #fff !important;
}

.white-text {
    color: white;
}

.handle-toggle-step2 {
    font-weight: 700;
    display: flex;
    color: #7563DC;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.guide-step-2 {
    margin-top: 13px;
    border-top: 1px solid #DFE3E8;
}

.line1 {
    display: flex;
    margin-top: 16px;
}

.line2 {
    display: flex
}

.span-guide {
    display: flex;
}

.icon-guide {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-left: 8px;
}

.icon-guide.text-1 {
    background: #7563DC;
}

.icon-guide.text-2 {
    background: #F7F7F7;
    margin-right: 80px;
}

.icon-guide.text-3 {
    background: rgba(169, 169, 184, 0.24);
}

.operator-name {
    font-size: 16px;
    text-align: right;
    margin-bottom: 7px;
    color: #4E4D59;
}

/* STEP 3 */

.form-body {
    padding: 6px 8px;
    border-radius: 8px;
}

.form-body input[type=text] {
    width: 100%;
    color: #8A8A99;
    padding: 4px 12px;
    border: 1px solid #8A8A99;
    border-radius: 8px;
    height: 40px;

}

.form-item {
    margin-bottom: 25px;
}

.form-title {
    width: 100%;
}


.toggle-switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.toggle-switch-option {
    position: relative;
    padding: 8px 16px;
    background-color: #F7F7F7;
    border-radius: 20px;
    cursor: pointer;
    width: 148px;
}

.toggle-switch-option.active {
    background-color: #7563DC;
    color: #fff;
}

.item-times {
    width: 100%
}

.item-times.selected {
    background-color: #7563DC;
    /* Change this to your desired background color */
    color: white;
}

.container-times {
    display: flex;
    flex-wrap: wrap;
}

.container-times>div {
    flex: 30%;
    padding: 8px;
    border: 0;
    border-radius: 8px;
    margin: 5px;
    background: #F7F7F7;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

}

/* STEP 4 */

.info-user-step4 {
    display: flex;
}

.info-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 120px;
    height: 56px;
    background: #F7F7F7;
    border: 1px solid #F5F5F7;
}

.info-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 224px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #F5F5F7;

}

.info-value-height {
    height: auto !important;
}

.info-user-border-one .info-user-step4:first-of-type .info-title {
    border-top-right-radius: 10px;
}

.info-user-border-one .info-user-step4:last-of-type .info-title {
    border-bottom-right-radius: 10px;
}

.info-user-border-one .info-user-step4:first-of-type .info-value {
    border-top-left-radius: 10px;
}

.info-user-border-one .info-user-step4:last-of-type .info-value {
    border-bottom-left-radius: 10px;
}

.margin-bottom-step4 {
    margin-bottom: 110px;
}

.success-payment {
    background: #00AD2029;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 30px;
    border-radius: 10px;
}

.success-payment h2 {
    color: #00AD20;
    font-size: 28px;
    font-weight: 700;
}

.error-payment {
    align-items: center;
    background: rgba(255, 69, 56, 0.16);
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    line-height: 30px;
    padding: 10px;
}

.error-payment h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #AA2E25;
}

.error-payment p {
    color: #4E4D59;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;

}

.unavailable-shift {
    color: #d3d3d3;
    cursor: not-allowed !important;
}

.mx-width {
    max-width: 1026px !important;
    margin: 0 auto;
    border: 1px solid #f4f4f4;
    padding: 15px;
    border-radius: 30px;
}

.btn-sub-reserve {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.container-reserve-dash {
    max-width: 625px;
    margin: 0 auto;
}