@font-face {
  font-family: Shabnam;
  src: url(../src/assets/fonts/Shabnam-Light.woff2) format("woff2"), url(../src/assets/fonts/Shabnam-Light.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Shabnam;
  src: url(../src/assets/fonts/Shabnam-Bold.woff2) format("woff2"), url(../src/assets/fonts/Shabnam-Bold.woff2) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Shabnam;
  src: url(../src/assets/fonts/Shabnam.woff2) format("woff2"), url(../src/assets/fonts/Shabnam.woff2) format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  direction: rtl;
  margin: 0;
  font-family: "Shabnam", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}

.picAvatar {
  width: 24px;
  height: 24px;
}

.borderOperator {
  border: 1px solid #f6f6f8;
  filter: drop-shadow(0px 1px 2px rgba(22, 28, 36, 0.08));
  border-radius: 8px;
}

.r-15 {
  right: 15px;
}

.logoutText {
  font-weight: 700;
  font-size: 16px;
  color: #FF4538;
}

.buttonLogoutOperator {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #fff;
  width: 100%;
}

.width-areas-pop {

  height: 540px;
}

.body-areas-pop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-area-cancel {
  border: 1px solid #7B7C81;
  border-radius: 8px;
  width: 100%;
  padding: 10px 9px
}

.select-area-cancel-items {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #2E3036;
}

.select-area-cancel-items-pic {
  width: 16px;
  height: 16px;
}

.link-pay a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}

.name-pay {
  font-weight: 700;
}

.pay-costumer {
  width: 79px;
  padding: 1px 8px;
  background: rgba(0, 173, 32, 0.16);
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  color: #00ad20;
}

.pay-costumer-cancel {
  text-align: center;
  width: 79px;
  padding: 1px 8px;
  background: rgba(255, 190, 38, 0.16);
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  color: #FFBE26;
}

.pay-costumer-pending {
  text-align: center;
  width: 100px;
  padding: 1px 8px;
  background: var(--gray-300, #E6E6E8);
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  color: #2E3036;
}

.pay-costumer-disabled {
  color: #919EABCC;
  font-size: 14px;
}

.modal-login-logout-operator {
  min-height: 250px;
}

.md-edit {
  margin: 220px auto !important;
  width: 480px !important;
}

.di-edit {
  display: contents !important;
}

.container-old-c {
  padding: 30px 10px;
  border: 1px solid #e3e1e1;
  border-radius: 10px;
}

.search-c {
  width: 100%;
  padding: 12px;
  border: 1px solid #3866F7;
  border-radius: 10px;
}

.select-opt {
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 10px;
}

.ul-search-c {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #F6F6F8;
  list-style: none;

}

.li-search-c {
  color: #2E3036;
  font-weight: 700;
  font-size: 16px;
  line-height: 33px;
  cursor: pointer;
}

.li-search-c:hover {
  background-color: #f8f8f8;
}

.li-not-found-c {
  padding: 5px 5px 20px 5px;
  color: #b90b0b;
  font-weight: 700;
}

.w-600 {
  width: 600px !important;
}

.h-pay {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 20px 0;
}

.logoutText {
  font-weight: 700;
  font-size: 16px;
  color: #ff4538;
  cursor: pointer;
}

.input-element-p {
  border: 1px solid #f6f6f8;
  border-radius: 8px !important;
  padding: 30px 15px 5px 5px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: #2e3036;
}

.input-element:focus {
  border: 1px solid #f6f6f8;
  border-radius: 8px;
  padding: 30px 5px 5px 5px;
}

.w-380 {
  width: 380px !important;
}

.item-back-pointer {
  cursor: pointer;
  width: 80px;
}

.multi-way-pay {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 19px;
}

.payment-notifaction-red-body {
  border: 1px solid #FF4538;
  background: linear-gradient(0deg, #FFC1BD, #FFC1BD), linear-gradient(0deg, #FF4538, #FF4538);
  padding: 21px, 16px, 21px, 16px;
  border-radius: 8px;
}

.payment-notifaction-success-body {
  border: 1px solid #00ba79;
  background: linear-gradient(0deg, #a7ee86, #bbf4ac), linear-gradient(0deg, #00cf29, #2bd781);
  padding: 21px, 16px, 21px, 16px;
  border-radius: 8px;
}

.payment-notifaction {
  padding: 25px;
  display: flex;
  justify-content: space-between;
}

.payment-button-notfication {
  font-weight: 700;
  cursor: pointer;
}