.chargeModalText {
  color: #3866f7;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.input-group {
  position: relative;
}
.input-label {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #7b7c81;
}
.input-element {
  border: 1px solid #f6f6f8;
  border-radius: 8px !important;
  padding: 30px 15px 5px 5px;
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: #2e3036;
}

.input-element:focus {
  border: 1px solid #f6f6f8;
  border-radius: 8px;
  padding: 30px 5px 5px 5px;
}

.btn-f-b {
  font-weight: 700 !important;
}

.pointClick {
  cursor: pointer;
}