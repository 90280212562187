$PrimaryColor: #FFFFFF;
$secondaryColor: #1F2128;
$terColor:#72767C;
$quatColor: #A162F7;

.sidebar-container{
    border: 1px solid #F6F6F8;
    box-shadow: 0px 1px 2px rgba(22, 28, 36, 0.08);
    border-radius: 0px;
    padding: 10px;
    height: 100vh;
    background-color: $PrimaryColor;
    
    a{
        text-decoration: none;
        color: $terColor;
    }

    ul{
        list-style: none;
        li{
            margin-bottom: 10px;
            padding: 10px;
            a{
                color: $terColor;
                img{
                    padding: 5px;
                }

            }
            span{
                font-size: 14px;
                font-weight: 500;
                margin-right: 5px;
            }
        }
        li:hover,  li.active{
            background-color: #F3F5F8;
            border-radius: 10px;
        }
    }

    .logo-container{
        h3{
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.9375rem;
            color: $secondaryColor;
        }
    }

    .bottom-list-container{
        width: 90%;
        bottom: 0;
    }

    @media (max-width: '1100px') {
        padding: 1.875rem .75rem;
        h3{
            font-size: 1.125rem;
        }
        span{
            font-size: 10px;
        }
    } 

    @media (max-width: '990px') {
        padding: 1.875rem .75rem;
        span, h3{
            display: none;
        }
    }
}