.margin300 {
  margin-top: 300px;
}

.heightTableUser {
  height: 648px;
  overflow: auto;
}
.heightTdCheckBox {
  width: 25px;
  height: 25px;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 0;
  padding: 16px;
}

#customers tr:nth-child(even) {
  background-color: #f9f9fb;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04aa6d;
  color: white;
}

/* 2 */
#customers2 {
  border-collapse: collapse;
  width: 100%;
}

#customers2 td,
#customers2 th {
  border: 0;
  padding: 16px;
}

#customers2 tr:nth-child(even) {
  background-color: #f9f9fb;
}

#customers2 tr:hover {
  background-color: #ddd;
}

#customers2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04aa6d;
  color: white;
}

.searchUser {
  border: 1px solid #7b7c81;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 40px;
  margin-bottom: 10px;
  background: url(../../assets/images/dashboard/images/search-normal.png) no-repeat;
  background-position: right 10px center;
}

input:focus.searchUser {
  outline: none;
}

.dailyTitle {
  font-size: 28px;
  font-weight: bold;
}

.btnDaily {
  border: 1px solid #3866f7;
  border-radius: 8px;
  color: #3866f7;
  background: transparent;
  padding: 8px 12px;
}

.toggleUser span {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.payButton {
  color: #3866f7;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.cancelPay {
  color: #ff4538;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.form-body-pad {
  max-width: 580px;
  border: 1px solid #d8d6d6;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
  min-height: 350px;
}

.input-group {
  align-items: center;
}

.input-label {
  margin-right: 10px;
}

.input-element {
  flex: 1;
}

.sum-pay {
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  align-items: center;
  text-align: right;
  color: #3866F7;
}

.sum-title {
  font-size: 12px;
  align-items: center;
  color: #A6A7AB;
}

.form-body-turn {
  margin: 20px;
  background: #FFFFFF;
  border: 1px solid #F6F6F8;
  border-radius: 8px;
  text-align: right;
}

.col-form-label {
  color: #2E3036 !important;
  font-size: 16px !important;
}

.submit-shift {
  display: flex ;
  justify-content: center;
  align-items: center;
}